import { Mapper } from '../../abstract/mapper';

export class ExchangeMapper extends Mapper {
  static mapCurrencyRate(currencyRate) {
    const {
      id,
      attributes: {
        source,
        ...restAttributes
      },
    } = currencyRate;

    const { commissions, rates, ...attrs } = restAttributes;

    const commissionDeposit = commissions?.deposit || 0;
    const commissionWithdrawal = commissions?.withdrawal || 0;

    const rateDeposit = rates?.deposit || 0;
    const rateWithdrawal = rates?.withdrawal || 0;
    const rateExternal = rates?.external || 0;

    return {
      id,
      commissionDeposit,
      commissionWithdrawal,
      rateDeposit,
      rateWithdrawal,
      rateExternal,
      ...attrs,
    };
  }

  static mapCurrencyRates(currencyRates = []) {
    return currencyRates.map(ExchangeMapper.mapCurrencyRate);
  }

  static mapAvailableSymbols(symbols = [], wrapIntoModel) {
    return symbols.map((symbol) => {
      const data = {
        id: symbol.id,
        symbol: symbol.attributes.symbol,
      };

      return ExchangeMapper._getResult(data, wrapIntoModel);
    });
  }
}
