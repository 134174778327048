import { isArray } from '../predicates/is-array';
import { isString } from '../predicates/is-string';
import { isNumber } from '../predicates/is-number';
import { isObject } from '../predicates/is-object';
import { isFunction } from '../predicates/is-function';
import { isBoolean } from '../predicates/is-boolean';

export class PropsValidator {
  static isIncludeValues(values = [], propName) {
    const errorMsg = `prop value must be one of: ${values.join(', ')}`;
    return (propValue) => values.includes(propValue) || PropsValidator._logError(propName, errorMsg);
  }

  static isArrayOf(propName) {
    const errorMsgFirstPart = 'prop value must be an array';

    const checkExec = (check, propValue, errorMsg) => (
      (isArray(propValue) && propValue.every(check)) || PropsValidator._logError(propName, errorMsg)
    );
    const getCheckEntities = (type) => ({
      check: PropsValidator._getCheckByType(type),
      errorMsg: PropsValidator._getErrorMsgByType(type, errorMsgFirstPart),
    });

    return {
      num(propValue) {
        const { check, errorMsg } = getCheckEntities('num');
        return checkExec(check, propValue, errorMsg);
      },
      str(propValue) {
        const { check, errorMsg } = getCheckEntities('str');
        return checkExec(check, propValue, errorMsg);
      },
      obj(propValue) {
        const { check, errorMsg } = getCheckEntities('obj');
        return checkExec(check, propValue, errorMsg);
      },
      fn(propValue) {
        const { check, errorMsg } = getCheckEntities('fn');
        return checkExec(check, propValue, errorMsg);
      },
      bool(propValue) {
        const { check, errorMsg } = getCheckEntities('bool');
        return checkExec(check, propValue, errorMsg);
      },
    };
  }

  static isArrayOfOneOf(types = [], propName) {
    const errorMsgFirstPart = 'prop value must be an array of one of types:';

    return (collectionValues) => collectionValues.reduce((acc, value) => ([
      ...acc,
      types
        .map((type) => PropsValidator._getCheckByType(type)(value))
        .some((res) => res),
    ]), []).every((res) => res) || PropsValidator._logError(propName, `${errorMsgFirstPart} ${types.join(', ')}`);
  }

  static isInstanceOf(obj, propName) {
    const errorMsg = `prop value must be the instance of ${obj.constructor.name}`;
    return (propValue) => propValue instanceof obj || PropsValidator._logError(propName, errorMsg);
  }

  static _getCheckByType(checkType) {
    const checkTypes = {
      str: isString,
      num: isNumber,
      obj: isObject,
      fn: isFunction,
      bool: isBoolean,
    };

    return checkTypes?.[checkType] || checkTypes.str;
  }

  static _getErrorMsgByType(checkType, errorMsgFirstPart) {
    const errorMsgLastParts = {
      str: 'of strings',
      num: 'of numbers',
      obj: 'of objects',
      fn: 'of functions',
      bool: 'of boolean',
    };

    const errorMsgLastPart = errorMsgLastParts?.[checkType] || errorMsgLastParts.str;
    return `${errorMsgFirstPart} ${errorMsgLastPart}`;
  }

  static _logError(propName, errorMsg) {
    console.error(`${propName}: ${errorMsg}`);
  }
}
