import { get } from '../utils/obj/get';
import { isString } from '../utils/predicates/is-string';
import { isNumber } from '../utils/predicates/is-number';
import { isFunction } from '../utils/predicates/is-function';
import { isEmpty } from '../utils/predicates/is-empty';

export class Guard {
  static notFunction(val, paramName) {
    const errorText = 'param must be a function';
    if (!isFunction(val)) Guard._callException(paramName, errorText);
  }

  static notNumber(val, paramName) {
    const errorText = 'param must be a number';
    if (!isNumber(val)) Guard._callException(paramName, errorText);
  }

  static notString(val, paramName) {
    const errorText = 'param must be a string type';
    if (!isString(val)) Guard._callException(paramName, errorText);
  }

  static notExistingObjValue(obj, pathToVal, paramName) {
    const errorText = `object param is required and should have value by this path: ${pathToVal}`;
    if (!get(obj, pathToVal, undefined)) Guard._callException(paramName, errorText);
  }

  static notIncludedValues(value, _values = [], paramName) {
    const errorText = `param must be one of: ${_values.join(', ')}`;
    if (!_values.includes(value)) Guard._callException(paramName, errorText);
  }

  static empty(value, paramName) {
    const errorText = 'param must not be empty';
    if (isEmpty(value)) Guard._callException(paramName, errorText);
  }

  static _callException(paramName = 'provided param', errorText) {
    throw new Error(`${paramName}: ${errorText}`);
  }
}
