import { Guard } from '../services/guard';

/**
 * The class is adapter for vue-notifications .notify method
 * Vue-notifications -> https://github.com/euvl/vue-notification
 */
export class UiNotifier {
  static _TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARN: 'warn',
    ERROR: 'error',
  };

  _vueNotifications;

  constructor(vueNotifications) {
    Guard.notExistingObjValue(vueNotifications, 'notify', 'notify');
    Guard.notFunction(vueNotifications.notify, 'notify');

    this._vueNotifications = vueNotifications;
  }

  success(text) {
    this._notify(UiNotifier._TYPES.SUCCESS, text);
  }

  info(text) {
    this._notify(UiNotifier._TYPES.INFO, text);
  }

  warn(text) {
    this._notify(UiNotifier._TYPES.WARN, text);
  }

  error(text) {
    this._notify(UiNotifier._TYPES.ERROR, text);
  }

  _notify(type, text) {
    this._vueNotifications.notify({ type, text });
  }
}
