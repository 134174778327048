export class CommonMapper {
  static mapPagination(data) {
    return {
      pageNumber: data.pagination.page.number,
      pageSize: data.pagination.page.size,
      totalPages: data.pagination.total.pages,
      totalRecords: data.pagination.total.records,
    };
  }
}
