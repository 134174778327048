export class Mapper {
  constructor() {
    if (new.target === Mapper) {
      throw new TypeError('Cannot construct abstract Mapper instances directly');
    }
  }

  static _getResult(mappedData, wrapIntoModel) {
    return wrapIntoModel ? wrapIntoModel(mappedData) : mappedData;
  }
}
