export class Api {
  _httpClient;
  _baseUrl;
  _queryParamsTranslator;

  constructor(httpClient, baseUrl, queryParamsTranslator) {
    Api._guardAgainstEmptyHttpClient(httpClient);

    this._httpClient = httpClient;
    this._baseUrl = baseUrl;
    this._queryParamsTranslator = queryParamsTranslator;
  }

  get queryParamsTranslator() {
    return this._queryParamsTranslator || throw new Error('queryParamsTranslator is not exists');
  }

  get({ endpoint, opts, mapper, withCredentials }) {
    return this._requestApi({
      method: 'get',
      endpoint,
      opts,
      mapper,
      withCredentials,
    });
  }

  post({ endpoint, opts, mapper, withCredentials }) {
    return this._requestApi({
      method: 'post',
      endpoint,
      opts,
      mapper,
      withCredentials,
    });
  }

  put({ endpoint, opts, mapper, withCredentials }) {
    return this._requestApi({
      method: 'put',
      endpoint,
      opts,
      mapper,
      withCredentials,
    });
  }

  patch({ endpoint, opts, mapper, withCredentials }) {
    return this._requestApi({
      method: 'patch',
      endpoint,
      opts,
      mapper,
      withCredentials,
    });
  }

  delete({ endpoint, opts, mapper, withCredentials }) {
    return this._requestApi({
      method: 'delete',
      endpoint,
      opts,
      mapper,
      withCredentials,
    });
  }

  async _requestApi({
    method,
    endpoint,
    opts,
    mapper,
    withCredentials,
  }) {
    Api._guardAgainstEmptyEndpoint(endpoint);
    return this._httpClient[method](endpoint, {
      params: opts?.params,
      url: opts?.url || this._baseUrl,
      data: opts?.data,
      headers: opts?.headers,
      withCredentials,
    }).then((data) => (mapper ? mapper(data) : data));
  }

  static _guardAgainstEmptyHttpClient(httpClient) {
    if (!httpClient) {
      throw new Error('httpClient is not defined');
    }
  }

  static _guardAgainstEmptyEndpoint(endpoint) {
    if (!endpoint) {
      throw new Error('request endpoint is not defined');
    }
  }
}
