export async function tryOneOf(asyncOperations = [], hasError) {
  const nextOperation = () => asyncOperations.slice(1);

  let result;

  try {
    result = await asyncOperations[0]();
  } catch (e) {
    if (hasError(e) && asyncOperations.length) {
      return tryOneOf(nextOperation(), hasError);
    }

    throw e;
  }

  return result;
}
