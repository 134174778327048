import { Api } from '../api';

export class AuthApi extends Api {
  // stage url - https://auth.stage.amarkets.dev/api

  // https://wiki.prod.amarkets.dev/doc/external-api-flr0YHgUwT#h-poluchenie-tokenov
  getToken({
    password,
    username,
    reCaptchaToken,
    platform,
    mapper,
    withCredentials,
    useCookies = false, // only for SSO authorizer
  }) {
    const endpoint = '/v1/token';
    const opts = {
      data: {
        grantType: 'password',
        password,
        username,
        platform,
        scope: 'openid',
      },
      headers: {},
    };

    if (useCookies) opts.headers['Use-Cookies'] = true;
    if (reCaptchaToken) opts.headers['Rc-Token'] = reCaptchaToken;

    return this.post({ endpoint, opts, mapper, withCredentials });
  }

  getTokenSSO({ clientId, code, redirectUri, codeVerifier }, mapper) {
    const endpoint = '/v1/token';
    const opts = {
      data: {
        grantType: 'authorization_code',
        clientId,
        code,
        redirectUri,
        codeVerifier,
        scope: 'openid',
      },
    };

    return this.post({ endpoint, opts, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/external-api-flr0YHgUwT#h-poluchenie-tokenov
  updateToken(refreshToken, mapper) {
    const endpoint = '/v1/token';
    const opts = {
      data: {
        grantType: 'refresh_token',
        refreshToken,
        scope: 'openid',
      },
    };

    return this.post({ endpoint, opts, mapper });
  }

  logout(refreshToken, mapper) {
    const endpoint = '/v1/sign_out';
    const opts = {
      data: {
        refreshToken,
      },
    };

    return this.post({ endpoint, opts, mapper });
  }

  // only for usage on the back-office
  getUser(userId, mapper) {
    const endpoint = '/v1/user_roles';
    const opts = {
      params: {
        'filter[uid]': userId,
      },
    };

    return this.get({ endpoint, opts, mapper });
  }

  // only for usage on the back-office
  getUsers(mapper) {
    // TODO: need to add pagination/filter/sorting query params
    const endpoint = '/v1/user_roles';

    return this.get({ endpoint, mapper });
  }

  // https://dark-meteor-265500.postman.co/workspace/api.amarkets~577ad361-992c-4d83-8be8-c97910e354e2/request/3419300-3503add9-fd12-4b7a-a48b-2a8b9491f348
  getUsersList(params, mapper) {
    const endpoint = '/v1/users';
    return this.get({
      endpoint,
      mapper,
      opts: {
        params,
      },
    });
  }

  // https://dark-meteor-265500.postman.co/workspace/api.amarkets~577ad361-992c-4d83-8be8-c97910e354e2/request/3419300-dc9e5cbe-7201-427f-9d62-b04d9ed6e0b7
  getUserRoles(mapper) {
    const endpoint = '/v1/available_roles';
    return this.get({ endpoint, mapper });
  }

  // https://dark-meteor-265500.postman.co/workspace/api.amarkets~577ad361-992c-4d83-8be8-c97910e354e2/request/3419300-2e467f52-2316-4c30-9c2e-05eae626efea
  updateUserRoles({ userId, roles }, mapper) {
    const endpoint = '/v1/roles';
    const opts = {
      data: {
        data: {
          type: 'roles',
          attributes: {
            uid: userId,
            roles,
          },
        },
      },
    };

    return this.put({ endpoint, opts, mapper });
  }
}
