import { isFunction } from '../../utils/predicates/is-function';
import { parseOauthValidationError } from './parsers';
import {
  STATUS_CODES,
  DEFAULT_MSG_BY_STATUS_CODE,
  DEFAULT_PARSERS_BY_STATUS_CODE,
  DEFAULT_MSG,
  MAX_ERROR_LENGTH,
} from './const';

const getPreparedError = (error) => error?.response?.data?.error
  || error?.response?.data?.errorsTree
  || error?.response?.data?.errorDescription
  || error.message
  || (typeof error === 'object' ? JSON.stringify(error) : error).slice(0, MAX_ERROR_LENGTH);

export class HttpErrorParser {
  _msgByStatusCode;
  _parsersByStatusCode;
  _defaultMsg;
  _i18n;
  _defaultErrorMessageMapper = (message) => message;

  constructor({
    msgByStatusCode = {},
    parsersByStatusCode = {},
    defaultMsg = DEFAULT_MSG, // for unknown error statuses
    i18n,
  } = {}) {
    this._msgByStatusCode = { ...DEFAULT_MSG_BY_STATUS_CODE, ...msgByStatusCode };
    this._parsersByStatusCode = { ...DEFAULT_PARSERS_BY_STATUS_CODE, ...parsersByStatusCode };
    this._defaultMsg = defaultMsg;
    this._i18n = i18n;
  }

  parse({
    error,
    errorMessageMapper = this._defaultErrorMessageMapper,
    description = '',
    isCreateExtendedDefaultErrorMessage = false,
  }) {
    const statusCode = error?.response?.status;

    const errorMsg = this._getErrorMsg({ isCreateExtendedDefaultErrorMessage, error, description, statusCode });

    if (!statusCode) {
      return HttpErrorParser._buildResponse({ msg: errorMsg }, errorMessageMapper);
    }

    if (HttpErrorParser._isOauthError(error)) {
      return HttpErrorParser._buildResponse({ statusCode, msg: parseOauthValidationError(error) }, errorMessageMapper);
    }

    const parser = this._parsersByStatusCode?.[statusCode];
    const msg = this._handleMsg(
      (parser && parser(error))
      || this._msgByStatusCode?.[statusCode],
    ) || errorMsg;

    return HttpErrorParser._buildResponse({ statusCode, msg }, errorMessageMapper);
  }

  _getErrorMsg({ isCreateExtendedDefaultErrorMessage, error, description, statusCode }) {
    const statusCodeMsg = statusCode ? ` Code: ${statusCode}. ` : ' ';

    const preparedError = getPreparedError(error);

    return isCreateExtendedDefaultErrorMessage
      ? `${preparedError}${statusCodeMsg}${description}`
      : this._handleMsg(this._defaultMsg);
  }

  _handleMsg(msg) {
    const i18nExists = this._i18n && isFunction(this._i18n?.t);
    return msg && i18nExists ? this._i18n.t(msg) : msg;
  }

  static _isOauthError(e) {
    const isValidationError = e.response.status === STATUS_CODES.VALIDATION;
    return isValidationError && e.response?.data?.errorDescription;
  }

  static _buildResponse({ statusCode, msg }, errorMessageMapper) {
    return {
      statusCode,
      msg: errorMessageMapper(msg),
    };
  }
}
