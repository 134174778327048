import { tryOneOf } from '../../utils/async/try-one-of';
import { Api } from '../api';
import { ExchangeMapper } from './exchange-mapper';
import { CommonMapper } from '../mappers/common-mapper';

export class ExchangeApi extends Api {
  // stage url - https://exchange.stage.amarkets.dev/api
  // prod url - https://exchange.prod.amarkets.dev/api

  static _QUERY_PARAM_MAPS = {
    searchExactQueryParamKeys: [],
    searchPartialQueryParamKeys: [],
    filterQueryParams: new Map([
      ['pageNumber', 'page[number]'],
      ['pageSize', 'page[size]'],
      ['symbol', 'filter[symbol]'],
      ['date', 'filter[date]'],
      ['from', 'filter[time[from]]'],
      ['to', 'filter[time[to]]'],
    ]),
    sortingQueryParams: new Map([
      ['time', 'time'],
      ['rateExternal', 'rates.external'],
      ['rateDeposit', 'rates.deposit'],
      ['rateWithdrawal', 'rates.withdrawal'],
      ['symbol', 'symbol'],
      ['commissionDeposit', 'commissions.deposit'],
      ['commissionWithdrawal', 'commissions.withdrawal'],
    ]),
  };

  // https://wiki.prod.amarkets.dev/doc/obmen-valyuty-jTnKlqOOay#h-poluchenie-istorii-kursov
  async getRatesHistory({ sorting = {}, filter = {} }) {
    const endpoint = '/v1/rates';
    const opts = {
      params: this.queryParamsTranslator.translate(ExchangeApi._QUERY_PARAM_MAPS, {
        ...filter,
        sorting,
      }),
    };

    return this
      .get({ endpoint, opts })
      .then((currencyRates) => ({
        data: ExchangeMapper.mapCurrencyRates(currencyRates.data),
        pagination: CommonMapper.mapPagination(currencyRates),
      }));
  }

  // https://wiki.prod.amarkets.dev/doc/obmen-valyuty-jTnKlqOOay#h-poluchenie-dostupnyh-valyutnyh-par
  getRatesCurrent({ sorting = {}, filter = {} }) {
    const endpoint = '/v1/rates/latest';
    const opts = {
      params: this.queryParamsTranslator.translate(ExchangeApi._QUERY_PARAM_MAPS, {
        ...filter,
        sorting,
      }),
    };

    return this
      .get({ endpoint, opts })
      .then((currencyRates) => ({
        data: ExchangeMapper.mapCurrencyRates(currencyRates.data),
        pagination: CommonMapper.mapPagination(currencyRates),
      }));
  }

  // https://wiki.prod.amarkets.dev/doc/obmen-valyuty-jTnKlqOOay#h-poluchenie-tekushego-kursa
  getRateCurrentBySymbol(symbol) {
    const endpoint = '/v1/rates/{:id}/latest'.replace('{:id}', symbol.toLowerCase());

    return this
      .get({ endpoint })
      .then((currencyRate) => ExchangeMapper.mapCurrencyRate(currencyRate.data));
  }

  getAvailableSymbols(wrapIntoModel) {
    const endpoint = '/v1/currency_pairs';

    return this
      .get({ endpoint })
      .then((symbols) => ExchangeMapper.mapAvailableSymbols(symbols.data, wrapIntoModel));
  }

  // https://wiki.prod.amarkets.dev/doc/obmen-valyuty-jTnKlqOOay#h-sozdanie-komissii
  createCurrencyCommission(commission) {
    const endpoint = '/v1/commissions';
    const opts = ExchangeApi._createCommissionPostData(commission);

    return this.post({ endpoint, opts });
  }

  // https://wiki.prod.amarkets.dev/doc/obmen-valyuty-jTnKlqOOay#h-obnovlenie-komissii
  async updateCurrencyCommission(commission) {
    const endpoint = `/v1/commissions/${commission.symbol}`;
    const id = commission.symbol;
    const opts = ExchangeApi._createCommissionPostData(commission, id);

    return this.patch({ endpoint, opts });
  }

  async updateOrCreateCurrencyCommission(commission) {
    const httpErrors = {
      notFound: 404,
      hasAlreadyExists: 422,
    };

    const requests = [
      () => this.updateCurrencyCommission(commission),
      () => this.createCurrencyCommission(commission),
    ];

    return tryOneOf(requests, (e) => Object.values(httpErrors).includes(e.response.status));
  }

  async updateCurrencyCommissionWithExternalRate(commission) {
    const endpoint = `/v1/rates/${commission.symbol}`;
    const opts = ExchangeApi._createCommissionWithExternalRatePostData(commission);

    return this.patch({ endpoint, opts });
  }

  static _createCommissionPostData(commission, id) {
    const data = {
      type: 'commissions',
      attributes: {
        symbol: commission.symbol,
        deposit: commission.deposit,
        withdrawal: commission.withdrawal,
        updateInversePair: commission.updateInversePair,
      },
    };

    return {
      data: {
        data: id ? { ...data, id } : data,
      },
    };
  }

  static _createCommissionWithExternalRatePostData(commission) {
    return {
      data: {
        data: {
          type: 'rates',
          attributes: {
            symbol: commission.symbol,
            external_rate: commission.externalRate,
            withdrawal_commission: commission.withdrawal,
            deposit_commission: commission.deposit,
            updateInversePair: commission.updateInversePair,
          },
        },
      },
    };
  }
}
