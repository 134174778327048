import humps from 'humps';

const decamelizeRequestConfig = (reqConfig) => ({
  ...reqConfig,
  params: reqConfig.params ? humps.decamelizeKeys(reqConfig.params) : reqConfig.params,
  data: reqConfig.data ? humps.decamelizeKeys(reqConfig.data) : reqConfig.data,
});

const rejectFinalErrorData = (err) => Promise.reject(err);

export default {
  success: [
    decamelizeRequestConfig,
  ],
  error: [
    rejectFinalErrorData,
  ],
};
