import { Api } from '../api';

export class ProcessingApi extends Api {
  // stage url - https://processing.stage.amarkets.dev/api

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-poluchenie-vseh-payment-systems
  getPaymentSystems(mapper) {
    const endpoint = '/payment-systems';

    return this.get({ endpoint, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-poluchitь-tipy-platezhnyh-instrumentov
  getPaymentMethods(mapper) {
    const endpoint = '/payment-methods';

    return this.get({ endpoint, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-poluchitь-valyuty
  getCurrencies(mapper) {
    const endpoint = '/currencies';

    return this.get({ endpoint, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-poluchitь-strany
  getCountries(mapper) {
    const endpoint = '/countries';

    return this.get({ endpoint, mapper });
  }

  createPaymentSystem(payload, mapper) {
    const endpoint = '/payment-systems';
    const opts = {
      data: {
        data: {
          type: 'payment_systems',
          attributes: {
            ...payload,
          },
        },
      },
    };

    return this.post({ endpoint, opts, mapper });
  }

  updatePaymentSystem({
    uid,
    ...attributes
  }, mapper) {
    const endpoint = `/payment-systems/${uid}`;
    const opts = {
      data: {
        data: {
          type: 'payment_systems',
          attributes: {
            ...attributes,
          },
        },
      },
    };

    return this.put({ endpoint, opts, mapper });
  }

  getRates({
    directions,
    acard,
    paymentSystemUid,
    currencies,
    countries,
    paymentInstruments,
    pageNumber,
    pageSize,
  } = {}, mapper) {
    const endpoint = '/payment-system-rates';
    const opts = {
      params: {
        directions,
        acard,
        paymentSystemUid,
        currencies,
        countries,
        paymentInstruments,
        'page[number]': pageNumber,
        'page[size]': pageSize,
      },
    };

    return this.get({ endpoint, opts, mapper });
  }

  updateRate({
    uid,
    ...payload
  }, mapper) {
    const endpoint = `/payment-system-rates/${uid}`;
    const opts = {
      data: {
        data: {
          type: 'payment_system_rates',
          attributes: {
            ...payload,
          },
        },
      },
    };

    return this.put({ endpoint, opts, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-sozdanie-rejta-acard
  createAcardRate({ uid }, mapper) {
    const endpoint = 'acard/rates';
    const opts = {
      data: {
        data: {
          type: 'payments',
          attributes: {
            rate: {
              uid,
            },
          },
        },
      },
    };

    return this.post({ endpoint, opts, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-udalenie-rejta-acard
  deleteAcardRate({ uid }, mapper) {
    const endpoint = `acard/rates/${uid}`;
    const opts = {
      data: {
        data: {
          type: 'payments',
          attributes: {
            uid,
          },
        },
      },
    };

    return this.delete({ endpoint, opts, mapper });
  }

  // https://wiki.prod.amarkets.dev/doc/processingovyj-centr-api-adminka-iPHvvLvHad#h-dobavlenie-dopolnitelьnogo-rejta
  createAdditionalRate(payload, mapper) {
    const endpoint = '/payment-system-additional-rates';
    const opts = {
      data: {
        data: {
          type: 'payment_system_additional_rates',
          attributes: {
            ...payload,
          },
        },
      },
    };

    return this.post({ endpoint, opts, mapper });
  }

  updateAdditionalRate({
    uid,
    ...payload
  }, mapper) {
    const endpoint = `/payment-system-additional-rates/${uid}`;
    const opts = {
      data: {
        data: {
          type: 'payment_system_additional_rates',
          attributes: {
            ...payload,
          },
        },
      },
    };

    return this.put({ endpoint, opts, mapper });
  }

  getPayments({
    paymentSystemRateUid,
    userAccountTypes,
    userAccountUuids,
    isAcard,
    pageNumber,
    pageSize,
    sortBy,
    sortDesc,
    statuses,
  } = {}, mapper) {
    const endpoint = '/payments';
    const opts = {
      params: {
        paymentSystemRateUid,
        userAccountTypes,
        userAccountUuids,
        isAcard,
        sortBy,
        sortDesc,
        statuses,
        'page[number]': pageNumber,
        'page[size]': pageSize,
      },
    };

    return this.get({ endpoint, opts, mapper });
  }

  createAgentDeposit({ uid, rateUid }, mapper) {
    const endpoint = `/acard/payments/${uid}`;

    const opts = {
      data: {
        data: {
          type: 'payments',
          attributes: {
            acardPaymentRate: {
              uid: rateUid,
            },
          },
        },
      },
    };

    return this.patch({ endpoint, opts, mapper });
  }

  getAcardDeposits({
    pageNumber,
    pageSize,
    sortBy,
    sortDesc,
    filters = {},
  } = {}, mapper) {
    const endpoint = '/acard/payments';
    const opts = {
      params: {
        sortBy,
        sortDesc,
        'page[number]': pageNumber,
        'page[size]': pageSize,
        ...filters,
      },
    };

    return this.get({ endpoint, opts, mapper });
  }

  patchPayment({
    uid,
    ...payload
  }, mapper) {
    const endpoint = `/payments/${uid}`;
    const opts = {
      data: {
        data: {
          type: 'payments',
          attributes: {
            ...payload,
          },
        },
      },
    };

    return this.patch({ endpoint, opts, mapper });
  }

  fetchAcardPaymentsPrepare({ uid, details, amount, currency }, mapper) {
    const endpoint = '/acard/payments/prepare';
    const opts = {
      data: {
        data: {
          type: 'payments',
          attributes: {
            user: {
              uid,
            },
            account: {
              uid,
              details,
            },
            charge: {
              amount,
              currency,
            },
          },
        },
      },
    };

    return this.post({ endpoint, opts, mapper });
  }
}
