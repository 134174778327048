import { VuexHelper } from './vuex-helper';

export class VuexFacadeBase {
  dispatch;
  getters;

  constructor(store, namespace) {
    VuexFacadeBase._guardAgainstWrongNamespace(store, namespace);

    this.dispatch = VuexHelper.withDispatchFrom(namespace, store);
    this.getters = VuexHelper.withGettersFrom(namespace, store);
    this.commit = VuexHelper.withCommitFrom(namespace, store);
  }

  static _guardAgainstWrongNamespace($store, namespace) {
    if (!$store.hasModule(namespace)) {
      throw new Error(`${namespace}: $store module is not exists`);
    }
  }
}
