import cookie from 'js-cookie';
import humps from 'humps';
import { Guard } from './guard';

export class Cookie {
  static set(key, value, opts = {}) {
    cookie.set(humps.decamelize(key), value, opts);
  }

  static get(key) {
    return cookie.get(humps.decamelize(key));
  }

  static remove(key, path) {
    Guard.notString(path, 'path');
    cookie.remove(humps.decamelize(key), { path });
  }
}
