import { parseBusinessLogicError, parseValidationError } from './parsers';

export const STATUS_CODES = {
  VALIDATION: 400, // special status code for a form validation from a server on a server
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
  BUSINESS_LOGIC: 422, // special status code for some kind of business logic from a server
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
};

export const DEFAULT_MSG_BY_STATUS_CODE = {
  [STATUS_CODES.UNAUTHORIZED]: 'You are not an authorized user. Please go through the authorization process.',
  [STATUS_CODES.FORBIDDEN]: 'Permission denied. You are not authorized to view this resource.',
  [STATUS_CODES.NOT_FOUND]: 'Resource not found or never existed.',
  [STATUS_CODES.GONE]: 'The resource no longer exists. Please contact our customer service.',
  [STATUS_CODES.TOO_MANY_REQUESTS]: 'Too many wrong login attempts. Please go through the captcha.',
  [STATUS_CODES.INTERNAL_SERVER_ERROR]: 'Something went wrong... We are already know about the problem and trying to solve it. Please, try again later.',
  [STATUS_CODES.BAD_GATEWAY]: 'Bad gateway. We are already know about the problem and trying to solve it. Please, try again later.',
};

export const DEFAULT_MSG = 'Something went wrong... Please try again later';

// In theory, these parsers will be used for all Amarkets SPA, there is no need to change them in a certain app
export const DEFAULT_PARSERS_BY_STATUS_CODE = {
  [STATUS_CODES.VALIDATION]: parseValidationError,
  [STATUS_CODES.BUSINESS_LOGIC]: parseBusinessLogicError,
};

export const MAX_ERROR_LENGTH = 100;
