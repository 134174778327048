import * as Sentry from '@sentry/vue';

export class SentryService {
  _sentry;

  constructor(config) {
    Sentry.init({
      ...config,
      beforeSend: config?.beforeSend || SentryService._beforeSend,
    });
    this._sentry = Sentry;
  }

  setUser(authData = {}) {
    this._sentry.setUser({
      id: authData?.userId || '',
      email: authData?.userLogin || 'not authorized',
    });
  }

  removeUser() {
    this._sentry.configureScope((scope) => scope.setUser(null));
  }

  error(exception) {
    this._captureException(exception, 'error');
  }

  warning(exception) {
    this._captureException(exception, 'warning');
  }

  info(exception) {
    this._captureException(exception, 'info');
  }

  debug(exception) {
    this._captureException(exception, 'debug');
  }

  _captureException(error, level) {
    this._sentry.captureException(error, level);
  }

  static _beforeSend(event, hint) {
    const value = event.exception?.values?.[0]?.value;
    const isNonErrorException = value?.startsWith('Non-Error exception captured');
    if (isNonErrorException) {
      const message = hint?.originalException?.message
        ? hint.originalException.message
        : JSON.stringify(
            hint?.originalException
            || event.extra?.__serialized__
            || { error: 'Unknown error! Check the "beforeSend" method in the SentryService configuration.' },
        );
      event.exception.values[0].value = message;
      event.message = message;
    }
    return event;
  }
}
