import { compose } from '../utils/fp/compose';

export class QueryParamsTranslator {
  static translate(queryParamsMap, queryParams) {
    // Server understands it like in SQL WHERE foo LIKE "xxx%"
    const LIKE = '%';
    // Server understands it like symbol of desc order
    const DESC = '-';
    // Search at the beginning of a string for fields like: humanId, code
    const createExactMatchSearchVal = (val) => `${val}${LIKE}`;
    // Set partial match search for fields like: email, name, accountDetails
    const createPartialMatchSearchVal = (val) => `${LIKE}${val}${LIKE}`;

    const { sorting: sortingParams, ...filterParams } = queryParams;
    const hasSortingParams = Object.values(sortingParams).length;

    const getValue = (key, val) => (
      (queryParamsMap.searchExactQueryParamKeys.includes(key) && createExactMatchSearchVal(val))
      || (queryParamsMap.searchPartialQueryParamKeys.includes(key) && createPartialMatchSearchVal(val))
      || val
    );
    const getAdaptedQueryParams = (filterEntries) => filterEntries.map(([filterParamName, filterValue]) => (
      [queryParamsMap.filterQueryParams.get(filterParamName), getValue(filterParamName, filterValue)]
    ));
    const filterFalseValues = (filterEntries) => (
      filterEntries.filter(([, filterValue]) => filterValue)
    );
    const adaptFilterQueryParams = compose(
      Object.fromEntries,
      getAdaptedQueryParams,
      filterFalseValues,
      Object.entries,
    );

    const createSortingParam = (sortingEntries) => ({
      sort: sortingEntries.flatMap((sortingEntry) => sortingEntry.reverse().join('')).join(','),
    });
    const getAdaptedSortingParams = (sortingEntries) => sortingEntries.map(([sortingParamName, sortingDirection]) => (
      [queryParamsMap.sortingQueryParams.get(sortingParamName), sortingDirection === 'desc' ? DESC : '']
    ));
    const adaptSortingQueryParams = compose(
      createSortingParam,
      getAdaptedSortingParams,
      Object.entries,
    );

    return hasSortingParams
      ? { ...adaptFilterQueryParams(filterParams), ...adaptSortingQueryParams(sortingParams) }
      : adaptFilterQueryParams(filterParams);
  }
}
