import { isNil } from '../utils/predicates/is-nil';
import { Guard } from './guard';
import { VuexHelper } from '../utils/vuex/vuex-helper';

export class AppModule {
  rootComponentAsyncFactory;
  storeAsyncFactory;
  props;
  routesFactory;
  storeModuleName;
  asyncData;
  eventsFactory;
  propsProviderFactory;

  constructor(rootComponentAsyncFactory, opts = {}) {
    if (opts?.eventsFactory) {
      Guard.notFunction(opts?.eventsFactory, 'eventsFactory');
    }

    Object.assign(this, { rootComponentAsyncFactory, ...opts });
  }

  register({
    moduleName,
    Vue,
    context = {},
    spinnerComponent,
    spinnerProps = {},
  }) {
    Guard.notString(moduleName, 'moduleName');
    Guard.notExistingObjValue(Vue, 'component', 'Vue');

    const self = this;
    const getEvents = (cmpCtx) => (self?.eventsFactory ? self.eventsFactory(cmpCtx) : {});
    const getProps = (cmpCtx) => (self?.propsProviderFactory ? self.propsProviderFactory(cmpCtx) : {});

    Vue.component(moduleName, {
      props: self.props,
      provide() {
        return {
          ...getEvents(this),
          ...getProps(this),
          context,
        };
      },
      async created() {
        const timerId = setTimeout(() => {
          this.isLoaderVisible = true;
        }, 300);

        try {
          const promises = [
            this.registerRoot,
            self?.storeAsyncFactory ? this.registerStoreModule : null,
          ];

          await Promise.all(
            promises
              .filter((promise) => !isNil(promise))
              .map((p) => p()),
          );

          this.isModuleLoaded = true;
          this.$emit('initialized'); // After this event it is possible to use module API
        } catch (e) {
          console.error(e);
          this.hasError = true;
        } finally {
          this.isLoaderVisible = false;
          clearTimeout(timerId);
        }
      },
      data: () => ({
        isModuleLoaded: false,
        hasError: false,
        isLoaderVisible: false,
      }),
      methods: {
        async registerRoot() {
          const cmp = await self.rootComponentAsyncFactory();
          this.$options.cmp = cmp.default;
        },
        async registerStoreModule() {
          const storeModule = await self.storeAsyncFactory();

          VuexHelper.registerStoreModule({
            store: context.store,
            module: storeModule.default(context),
            moduleName: self.storeModuleName,
          });
        },
      },
      render(h) {
        // TODO: add the ability to pass error cmp as an argument of register
        if (this.hasError) {
          return h('p', { style: { color: 'red' } }, 'some error occurred, please try again later');
        }

        if (this.isLoaderVisible) {
          return spinnerComponent ? h(spinnerComponent, { props: spinnerProps }) : null;
        }

        if (this.isModuleLoaded) {
          return h(
            this.$options.cmp,
            {
              staticClass: this.$options.cmp.name,
              attrs: {
                ...this.$props,
              },
            },
            this.$slots.default,
          );
        }

        return null;
      },
    });

    if (self?.routesFactory) {
      context.router.addRoute(
        self.routesFactory(
          Vue.component(moduleName),
        ),
      );
    }
  }
}
