import { merge } from '../obj/merge';

export class VuexHelper {
  /**
   * Dynamically register the modules module once.
   * Ignores registration if module has already been registered.
   */
  static registerStoreModule({ store, module, moduleName }) {
    if (!store.hasModule(moduleName)) {
      store.registerModule(moduleName, module);
    }
  }

  static withDispatchFrom(moduleName, store) {
    return (actionName, payload) => {
      const { dispatch } = store;
      const namespace = `${moduleName}/${actionName}`;

      return dispatch(namespace, payload);
    };
  }

  static withGettersFrom(moduleName, store) {
    return (getterName, args) => {
      const { getters } = store;
      const namespace = `${moduleName}/${getterName}`;

      return args ? getters[namespace](args) : getters[namespace];
    };
  }

  static withCommitFrom(moduleName, store) {
    return (mutationName, args) => {
      const { commit } = store;
      const namespace = `${moduleName}/${mutationName}`;

      return commit(namespace, args);
    };
  }

  static createStoreModule({
    states = [],
    actions = [],
    mutations = [],
    getters = [],
  }) {
    const mergeEntities = (storeEntities = []) => (
      storeEntities.reduce((acc, entity) => merge(acc, entity), {})
    );

    return {
      namespaced: true,
      state: () => mergeEntities(states),
      actions: mergeEntities(actions),
      mutations: mergeEntities(mutations),
      getters: mergeEntities(getters),
    };
  }
}
