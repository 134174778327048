import humps from 'humps';

const camelizeSuccessData = (resp) => ({ ...resp, data: humps.camelizeKeys(resp.data) });
const parseFinalSuccessData = (resp) => (resp?.data ? resp.data : resp);

const camelizeErrorData = (err) => {
  const hasStatus = err?.response?.status;

  if (hasStatus) {
    const e = err;

    e.response.data = humps.camelizeKeys(e.response.data);

    return e;
  }

  return err;
};
const rejectFinalErrorData = (err) => Promise.reject(err);

export default {
  success: [
    camelizeSuccessData,
    parseFinalSuccessData,
  ],
  error: [
    camelizeErrorData,
    rejectFinalErrorData,
  ],
};
