import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import baseReqInterceptors from './base-req-interceptors';
import baseRespInterceptors from './base-resp-interceptors';
import { hasIn } from '../../utils/predicates/has-in';

export class HttpClient {
  _axios;

  constructor({
    baseUrl,
    timeout = 5000,
    reqInterceptorsSuccess = [],
    respInterceptorsSuccess = [],
    reqInterceptorsReject = [],
    respInterceptorsReject = [],
    onTokenExpired,
    onTokenFail,
    // https://github.com/axios/axios#interceptors
    synchronous = {},
    pauseInstanceWhileRefreshing = true,
  } = {}) {
    this._axios = axios.create({
      baseURL: baseUrl,
      timeout,
    });

    this._axios.interceptors.request.use(
      HttpClient._useInterceptors([
        ...baseReqInterceptors.success,
        ...reqInterceptorsSuccess,
      ]),
      HttpClient._useInterceptors([
        ...reqInterceptorsReject,
        ...baseReqInterceptors.error,
      ]),
      synchronous,
    );

    this._axios.interceptors.response.use(
      HttpClient._useInterceptors([
        ...baseRespInterceptors.success,
        ...respInterceptorsSuccess,
      ]),
      HttpClient._useInterceptors([
        ...respInterceptorsReject,
        ...baseRespInterceptors.error,
      ]),
    );

    const refreshAuthLogic = (failedRequest) => onTokenExpired(failedRequest).then((response) => {
      failedRequest.response.config.headers.Authorization = `Bearer ${response?.accessToken}`;
      return Promise.resolve();
    }).catch((error) => {
      onTokenFail();
      return Promise.reject(error);
    });

    this._useRefreshTokenInterceptor({ refreshAuthLogic, pauseInstanceWhileRefreshing });
  }

  get(endpoint, { params, url, withCredentials, headers } = {}) {
    return this._makeRequest({
      method: 'get',
      endpoint,
      params,
      url,
      headers,
      withCredentials,
    });
  }

  post(endpoint, {
    params,
    url,
    data,
    withCredentials,
    headers,
  } = {}) {
    return this._makeRequest({
      method: 'post',
      endpoint,
      params,
      data,
      url,
      headers,
      withCredentials,
    });
  }

  patch(endpoint, {
    params,
    url,
    data,
    headers,
    withCredentials,
  } = {}) {
    return this._makeRequest({
      method: 'patch',
      endpoint,
      params,
      data,
      url,
      headers,
      withCredentials,
    });
  }

  put(endpoint, {
    params,
    url,
    data,
    headers,
    withCredentials,
  } = {}) {
    return this._makeRequest({
      method: 'put',
      endpoint,
      params,
      data,
      url,
      headers,
      withCredentials,
    });
  }

  delete(endpoint, {
    params,
    url,
    data,
    headers,
    withCredentials,
  } = {}) {
    return this._makeRequest({
      method: 'delete',
      endpoint,
      params,
      data,
      url,
      headers,
      withCredentials,
    });
  }

  _makeRequest({
    method,
    endpoint,
    params,
    data,
    url,
    headers,
    withCredentials = false,
  }) {
    const baseURL = url || this._axios.defaults.baseURL;
    const baseReqConfig = { baseURL, params, headers, withCredentials };
    const request = this._axios[method];

    const requestsByMethod = {
      // despite the fact that DELETE method may have body, signature of the method different from methods
      // with body(POST, PUT, PATCH) https://github.com/axios/axios#axiosposturl-data-config-1
      delete: () => request(endpoint, { ...baseReqConfig, data }),
      get: () => request(endpoint, baseReqConfig),
    };

    return hasIn(requestsByMethod, method) ? requestsByMethod[method]() : request(endpoint, data, baseReqConfig);
  }

  _useRefreshTokenInterceptor({ refreshAuthLogic, pauseInstanceWhileRefreshing }) {
    const opts = {
      pauseInstanceWhileRefreshing,
      statusCodes: [401, 403],
    };

    createAuthRefreshInterceptor(this._axios, refreshAuthLogic, opts);
  }

  static _useInterceptors(interceptors = []) {
    return (payload) => {
      if (interceptors.length) {
        const processedPayload = interceptors[0](payload);
        const restInterceptors = interceptors.slice(1);
        const useNextInterceptor = HttpClient._useInterceptors(restInterceptors);

        return restInterceptors.length ? useNextInterceptor(processedPayload) : processedPayload;
      }

      return payload;
    };
  }
}
