import { isArray } from '../../utils/predicates/is-array';
import { get } from '../../utils/obj/get';
import { compose } from '../../utils/fp/compose';

const flattenValidationErrorTree = (errorTree) => {
  const result = {};

  const traverse = (t) => {
    const treeKeys = Object.keys(t);

    treeKeys.forEach((treeKey) => {
      const treeElement = t[treeKey];

      if (isArray(treeElement)) {
        result[treeKey] = treeElement;
      } else {
        traverse(treeElement);
      }
    });
  };

  traverse(errorTree);
  return result;
};

/**
 * Parser for error tree of 400(server validation error)
 * From
 * {
 *   data: {
 *     attributes: {
 *       charge: {
 *         amount: ['error1', 'error1'],
 *       },
 *       user: {
 *         data: {
 *           phoneNumber: {
 *             email: ['error3', 'error4'],
 *           },
 *         },
 *       },
 *       age: ['error4', 'error5'],
 *     },
 *   },
 * }
 *
 * To
 * [
 *   { amount: ['error1', 'error1'] },
 *   { phoneNumber: ['error3', 'error4'] },
 *   { count: ['error4', 'error5'] },
 * ]
 */
export const parseValidationError = (error) => {
  const buildErrorsCollection = (entries) => (
    entries.reduce((errors, [fieldName, fieldErrors]) => {
      errors.push({ [fieldName]: fieldErrors });
      return errors;
    }, [])
  );

  return compose(
    flattenValidationErrorTree,
    buildErrorsCollection,
    Object.entries,
  )(error.response.data.errors);
};

export const parseBusinessLogicError = (e) => {
  const withBasePath = (pathPart) => `response.data.${pathPart}`;
  return get(e, withBasePath('errors')) || get(e, withBasePath('errorsTree'));
};

export const parseOauthValidationError = (e) => e.response.data.errorDescription;
