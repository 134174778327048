import { isArray } from '../utils/predicates/is-array';
import { isFunction } from '../utils/predicates/is-function';
import { Guard } from './guard';
import { isObject } from '../utils/predicates/is-object';
import { capitalize } from '../utils/str/capitalize';

export class ErrorHandler {
  _httpErrorParser;
  _errorTracker;
  _uiNotifier;
  _logger;
  _i18n;
  _defaultErrorMsg;

  constructor({
    httpErrorParser,
    errorTracker, // an error tracking instance such as Sentry or BugSnag etc...
    uiNotifier,
    logger,
    i18n, // optional, only if you need to translate the default error message below
    defaultErrorMsg = 'Something went wrong... Please try again later',
    showAllDefaultErrorMessageExtended = false,
  }) {
    Guard.notFunction(httpErrorParser?.parse, 'httpErrorParser');
    Guard.notFunction(errorTracker?.error, 'errorTracker');
    Guard.notFunction(uiNotifier?.error, 'uiNotifier');
    Guard.notFunction(logger?.error, 'logger');

    this._httpErrorParser = httpErrorParser;
    this._errorTracker = errorTracker;
    this._uiNotifier = uiNotifier;
    this._logger = logger;
    this._i18n = i18n;
    this._defaultErrorMsg = defaultErrorMsg;
    this._showAllDefaultErrorMessageExtended = showAllDefaultErrorMessageExtended;
  }

  handle(
    error,
    description,
    {
      skipErrorByOneOfCodes = [],
      errorMessageMapper = undefined,
      multipleNotify = false,
    } = {},
  ) {
    try {
      const { msg, statusCode } = this._httpErrorParser.parse({
        error,
        errorMessageMapper,
        description,
        isCreateExtendedDefaultErrorMessage: this._showAllDefaultErrorMessageExtended,
      });

      if (!statusCode || (statusCode && !skipErrorByOneOfCodes?.includes(statusCode))) {
        this._showErrorMsg(msg, multipleNotify);
      }
    } catch (parsingError) {
      const errorMsg = this._getErrorMsg({ error, description });

      this._showErrorMsg(errorMsg);

      // additionally, when httpErrorParser failed to parse an error
      this._sendError(parsingError);
      this._logError(parsingError, description);
    } finally {
      this._sendError(error);
      this._logError(error, description);
    }
  }

  get _defaultMessage() {
    const i18nExists = this._i18n && isFunction(this._i18n?.t);
    return i18nExists ? this._i18n.t(this._defaultErrorMsg) : this._defaultErrorMsg;
  }

  _getErrorMsg = ({ error, description }) => {
    const maxErrorLength = 100;

    const preparedError = error.message || (typeof error === 'object' ? JSON.stringify(error) : error).slice(0, maxErrorLength);

    return this._showAllDefaultErrorMessageExtended
      ? `${preparedError} ${description || ''}`
      : this._defaultMessage;
  };

  _showErrorMsg(msg, multipleNotify) {
    if (multipleNotify && isArray(msg)) {
      msg.forEach((message) => this._uiNotifier.error(message));
    } else if (isObject(msg)) {
      const msgObj = msg;
      // For object of errors like
      // {email: ['email is required', 'incorrect email'], phone: ['phone is required']}
      Object.entries(msgObj).forEach(([key, field]) => {
        if (Array.isArray(field)) {
          msgObj[key] = field.map((item) => this._uiNotifier.error(capitalize(item)));
        }
      });
    } else {
      this._uiNotifier.error(msg);
    }
  }

  _sendError(e) {
    this._errorTracker.error(e);
  }

  _logError(e, description) {
    const log = this._logger.error;

    if (description) {
      log(e, description);
    } else {
      log(e);
    }
  }
}
